import React, { Component } from 'react';
import { Dialog, DialogContent } from '@rmwc/dialog';
import { Icon } from '@rmwc/icon';
import { Button } from '@rmwc/button';

import '../styles/agent-outbound-welcome.scss';

interface AgentOutboundWelcomeProps {
    translate: (key: string) => string;
    onClose?: () => any;
}

interface AgentOutboundWelcomeState {
    open: boolean;
}

/**
 * Welcome popup for the new UI of agent outbound
 */
class AgentOutboundWelcome extends Component<AgentOutboundWelcomeProps, AgentOutboundWelcomeState> {
    constructor(props: AgentOutboundWelcomeProps) {
        super(props);
        this.state = {
            open: true,
        };
    }

    closeDialog = () => {
        this.setState({open: false}, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        });
    }

    changeUi = (ui) => {
        const params = {
            value: ui,
        };
        $.ajax({
            type: 'POST',
            url: '?page=ajax&module=agent-settings&cmd=select_theme',
            dataType: 'json',
            data: {params},
            success: () => {
                (window as any).UserInfo.theme = ui;
                (window as any).UserInfo.show_welcome_dialog = false;
                if (ui === 'old_ui') {
                    this.closeDialog();
                } else {
                    location.reload();
                }
            }
        });
    }

    changeToNewUi = () => {
        this.changeUi('modern_ui');
    }

    changeToOldUi = () => {
        this.changeUi('old_ui');
    }

    render() {
        const {translate} = this.props;
        return (
            <>
                <Dialog open={this.state.open} className='leaddesk-agent-outbound-welcome'>
                    <DialogContent>
                        <Icon icon='close' onClick={this.changeToOldUi} tabIndex={0}/>
                        <img src='www/assets/images/modern_ui/computer-large.png'/>
                        <div className='title-text'>{translate('agent.outbound.welcome_popup.title.label')}</div>
                        <div className='content-text'>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: translate('agent.outbound.welcome_popup.content.first_paragraph.info'),
                                }}
                            />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: translate('agent.outbound.welcome_popup.content.second_paragraph.info'),
                                }}
                            />
                        </div>
                        <div className='actions'>
                            <Button raised onClick={this.changeToNewUi} tabIndex={-1}>
                                {translate('agent.outbound.welcome_popup.actions.accept.button')}
                            </Button>
                            <a onClick={this.changeToOldUi}
                               tabIndex={-1}>{translate('agent.outbound.welcome_popup.actions.reject.button')}</a>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default AgentOutboundWelcome;
