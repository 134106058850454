import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import map from 'lodash/map';
import values from 'lodash/values';
import AjaxChartComponent from './AjaxChartComponent';
import {lang} from '../core/localization';
import installDoughnutText from '../extensions/chartjs/doughnutText';

interface IState {
    open: boolean;
}

interface IProps {
    listId: number;
    listName?: string;
}

interface IHistogramItem {
    label: string;
    value: string;
}

interface IDoughnutDataItem {
    name: string;
    value: string;
    desc: string;
}

interface IDoughnutMetaData {
    major?: string;
    minor?: string;
    suffix?: string;
}

interface IDoughnutData {
    data: Array<IDoughnutDataItem>;
    meta?: IDoughnutMetaData;
}

/**
 * Material UI dialog component that displays contact list statistics
 */
export default class StatisticsDialog extends React.Component<IProps, IState> {

    public static pickStackedColor(index: number) : string {
        const stackedColors = [
            '#f6511d',
            '#b33316',
            '#11bbee',
            '#0b7798',
            '#ffb400',
            '#a37300',
            '#7fb800',
            '#517600',
            '#0d2c54',
            '#091d36',
        ];
        return stackedColors[index % stackedColors.length];
    }

    constructor(props: IProps) {
        super(props);
        this.state = {
            open: true,
        };
    }

    public componentDidMount() {
        installDoughnutText();
    }

    public handleClose = () => {
        this.setState({ open: false });
    }

    public formatHistogram(data) {
        const yValues = map(data, (val: IHistogramItem) => val.value);
        const xValues = map(data, (val: IHistogramItem, key) => val.label);
        return {
            datasets: [{
                backgroundColor: 'rgba(86,184,233,0.4)',
                borderColor: 'rgba(86,184,233,1)',
                data: yValues,
            }],
            labels: xValues,
        };
    }

    public formatDoughnut(data) {
        const labels = map(data.data, (item: IDoughnutDataItem) => item.name);
        const statistics = map(data.data, (item: IDoughnutDataItem) => parseInt(item.value, 10));
        const descriptions = map(data.data, (item: IDoughnutDataItem) => item.desc);

        return {
            datasets: [{
                backgroundColor: ['#7ebb25', '#f8ab1c', '#e58818', '#e3281f', '#ac1d10'],
                borderWidth: 0,
                data: statistics,
            }],
            descriptions,
            labels,
            overlay: {
                major: ('major' in data.meta) ? String(data.meta.major) : '',
                minor: ('minor' in data.meta) ? String(data.meta.minor) : '',
                suffix: ('suffix' in data.meta) ? String(data.meta.suffix) : '',
            },
        };
    }

    public formatMulti(data) {
        const dataSets = [];
        values(data.data).forEach((val, index) => {
            const color = StatisticsDialog.pickStackedColor(index);
            dataSets.push({
                backgroundColor: color,
                borderColor: color,
                data: values(val.data),
                label: val.name,
                lineTension: 0,
                pointRadius: 0,
            });
        });
        return {
            datasets: dataSets,
            labels: data.labels,
        };
    }

    public pad(text: string, size: number) {
        while (text.length < size) {
            text = '\u2007' + text; // U+2007 figure space
        }
        return text;
    }

    public render() {
        const flexStyle = {
            display: 'flex',
            fontFamily: 'Roboto',
        };
        const colStyle = {
            flex: 1,
        };
        const secondaryColStyle = {
            flex: 1,
            marginLeft: 24,
        };

        const outAnswerRateUrl = '?page=ajax_controller&module=ContactListStatistics&cmd=outbound_ar_per_day&id=' + this.props.listId;
        const arChartOptions = {
            responsive: false,
            scales: {
                yAxes: [{
                    ticks: {
                        callback: (value, index) => value + '%',
                        max: 100,
                        min: 0,
                    }
                }]
            }
        };

        const outCallCountUrl = '?page=ajax_controller&module=ContactListStatistics&cmd=outbound_count_per_day&id=' + this.props.listId;
        const outCallCountOptions = {
            responsive: false,
            scales: {
                yAxes: [{
                    ticks: {
                        callback: (value, index) => this.pad(String(value), 4),
                        min: 0,
                    }
                }]
            }
        };
        const contactStatusUrl = '?page=ajax_controller&module=ContactListStatistics&cmd=contact_status&id=' + this.props.listId;
        const contactStatusOptions = {
            cutoutPercentage: 70,
            responsive: false,
        };

        const phoneNumberStatusUrl = '?page=ajax_controller&module=ContactListStatistics&cmd=number_status&id=' + this.props.listId;
        const phoneNumberStatusOptions = {
            cutoutPercentage: 70,
            responsive: false,
        };

        const reasonsUrl = '?page=ajax_controller&module=ContactListStatistics&cmd=reasons_per_day&id=' + this.props.listId;
        const reasonsDailyOptions = {
            events: ['click'],
            responsive: false,
            scales: {
                yAxes: [{
                    stacked: true,
                    ticks: {
                        min: 0,
                    }
                }]
            },
            tooltips: {
                enabled: false
            },
        };
        return (
            <div>
                <Dialog
                  maxWidth={'lg'}
                  fullWidth={true}
                  open={this.state.open}
                  onClose={this.handleClose}
                  aria-labelledby="form-dialog-title"
                  scroll="body"
                >
                  <DialogTitle id="form-dialog-title" style={{textAlign: 'start'}}>{this.props.listName || lang('list_unnamed')}</DialogTitle>
                  <DialogContent>
                    <div style={flexStyle}>
                        <div style={colStyle}>
                            <AjaxChartComponent type="doughnut" title={lang('admin.contact_list.stats.graph.contact_status.title')} url={contactStatusUrl} options={contactStatusOptions} dataFormatter={this.formatDoughnut} legend="bottom" height={170} width={170}/>
                            <div style={{height: 24}}></div>
                            <AjaxChartComponent type="doughnut" title={lang('admin.contact_list.stats.graph.phone_status.title')} url={phoneNumberStatusUrl} options={phoneNumberStatusOptions} dataFormatter={this.formatDoughnut} legend="bottom" height={170} width={170} />
                        </div>
                        <div style={secondaryColStyle}>
                            <AjaxChartComponent type="line" title={lang('admin.contact_list.stats.graph.out_call_ar.title')} url={outAnswerRateUrl} options={arChartOptions} dataFormatter={this.formatHistogram} height={190} width={350} />
                            <div style={{height: 4}}></div>
                            <AjaxChartComponent type="line" title={lang('admin.contact_list.stats.graph.out_call_count.title')} url={outCallCountUrl} options={outCallCountOptions} dataFormatter={this.formatHistogram} height={190} width={350} />
                        </div>
                        <div style={secondaryColStyle}>
                            <AjaxChartComponent type="line" title={lang('admin.contact_list.stats.graph.reasons_per_day.title')} url={reasonsUrl} options={reasonsDailyOptions} dataFormatter={this.formatMulti} legend="top" height={190} width={350} />
                        </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose} color="primary" style={{fontFamily: 'Roboto-Bold'}}>{lang('button_ok')}</Button>
                  </DialogActions>
                </Dialog>
            </div>
        );
    }
}
