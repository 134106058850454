import React from 'react';
import ReactDOM from 'react-dom';
import StatisticsDialog from './ui/StatisticsDialog';
import AgentOutboundWelcome from './ui/AgentOutboundWelcome';
import AgentNotificationPopUp from './ui/AgentNotificationPopUp';
import campaignURLHandler from './campaignURLHandler';
import { supportedOutputFormat } from '../reports/utils/helpers';
import lang from '../utils/intl';
import { AppControl } from './core/appcontrol';
import './survey/survey';
import axios from 'axios';

(window as any).Machine = {
    UI: {
        ContactLists: {
            showStatisticsDialog: (listId: number, listName: string = '') => {
                const dialogRoot = document.createElement('div');
                ReactDOM.render(<StatisticsDialog listId={listId} listName={listName} />, dialogRoot);
            },
        },
        AgentOutbound: {
            showNewUIWelcome: () => {
                const dialogRoot = document.createElement('div');
                dialogRoot.id = 'agent-outbound-react-root';
                document.body.appendChild(dialogRoot);
                ReactDOM.render(
                    <AgentOutboundWelcome
                        translate={lang}
                        onClose={() => {
                            dialogRoot.remove();
                        }}
                    />,
                    dialogRoot
                );
            },
        },
        PopUps: {
            showPopUp: popupData => {
                const dialogRoot = document.createElement('div');
                dialogRoot.id = 'agent-outdated-application-react-root';
                document.body.appendChild(dialogRoot);
                ReactDOM.render(
                    <AgentNotificationPopUp
                        translate={lang}
                        popupData={popupData}
                        onClose={() => {
                            dialogRoot.remove();
                        }}
                    />,
                    dialogRoot
                );
            },
        },
    },
    Reports: {
        filterSupportedOutputFormats: supportedOutputFormat,
    },
};

// Set Material UI to use new typography and remove deprecation warning
(window as any).__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

// Legacy globals
(window as any).campaignURLHandler = campaignURLHandler;

(window as any).pushPiwikTrackEvent = AppControl.pushPiwikTrackEvent;

window.addEventListener('load', function() {
    const url = window.location.search;
    const params = new URLSearchParams(url);
    const page = params.get('page') || '';
    const view = params.get('view') || '';

    // if the page is empty -> agent is probably logged out
    if (page) {
        const sessionStorage = (window as any).SettingsVault.sessionStorage();
        const popUp = sessionStorage && sessionStorage.popUp();
        if (popUp) {
            axios.post('?page=ajax_controller&module=PopUp&cmd=fetch_popups_for_agent', { data: { view, page } }).then(function(response) {
                if (response.data != null) {
                    response.data.forEach(popupData => {
                        if (
                            (window as any).Features.SHOW_POPUP_NOTIFICATIONS_AFTER_LOGIN &&
                            popUp.isPopUpShowedOnce(popupData.popup_id) != 0 &&
                            popupData.display === 'always'
                        ) {
                            popUp.setPopUpShowedOnce(popupData.popup_id);
                            (window as any).Machine.UI.PopUps.showPopUp(popupData);
                        } else if (
                            (window as any).Features.SHOW_POPUP_NOTIFICATIONS_AFTER_LOGIN &&
                            popUp.isPopUpShowedOnce(popupData.popup_id) == 0
                        ) {
                            popUp.setPopUpShowedOnce(popupData.popup_id);
                            (window as any).Machine.UI.PopUps.showPopUp(popupData);
                        }
                    });
                }
            });
        }
    }
});
