import chartjs from 'chart.js';

interface ICenterExtension {
    // Major text shown in center of doughnut
    textMajor?: string;
    // Minor text shown below the major
    textMinor?: string;
    // Minor suffix text shown right side of minor text (smaller font)
    textMinorSuffix?: string;
    // CSS color of all texts
    color?: string;
}

/**
 * Plugin to draw text in center of doughnut chart
 * @see ICenterExtension for possible options
 */
export function doughnutText(chart: any) {
    if (chart.config.options.plugins.center) {
        const ctx = chart.chart.ctx;

        // Get options from the center object in options
        const centerConfig: ICenterExtension = chart.config.options.plugins.center;
        const fontStyle = 'Roboto-Bold';
        const txt = centerConfig.textMajor || '';
        const color = centerConfig.color || '#000';
        const sidePaddingCalculated = 0.2 * (chart.innerRadius * 2);

        // Start with a base font of 30px
        ctx.font = '30px ' + fontStyle;

        // Get the width of the string and also the width of the element minus padding
        const stringWidth = ctx.measureText(txt).width;
        const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

        // Find out how much the font can grow in width.
        const widthRatio = elementWidth / stringWidth;
        const newFontSize = Math.min(Math.floor(30 * widthRatio), 40);
        const elementHeight = (chart.innerRadius * 2);

        // Pick a new font size so it will not be larger than the height of label.
        const majorFontSize = Math.min(newFontSize, elementHeight);

        // major text position
        const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
        const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

        // font settings
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = color;

        // draw major text
        ctx.font = majorFontSize + 'px ' + fontStyle;
        ctx.fillText(txt, centerX, centerY);

        if (centerConfig.textMinor) {
            // draw minor text
            const minorFontSize = Math.ceil(majorFontSize * 0.60);
            const minorY = centerY + (chart.innerRadius / 2);
            ctx.font = minorFontSize + 'px ' + 'Roboto';
            ctx.fillText(centerConfig.textMinor, centerX, minorY);

            // draw minor text label suffix if defined
            if (centerConfig.textMinorSuffix) {
                const suffixFontSize = Math.ceil(majorFontSize * 0.25);
                const suffixY = centerY + (chart.innerRadius * 3 / 4);
                ctx.font = suffixFontSize + 'px ' + 'Roboto';
                ctx.fillText(centerConfig.textMinorSuffix, centerX, suffixY);
            }
        }
    }
}

export default function install() {
    if (!(install as any).done) {
        chartjs.pluginService.register({
            beforeDraw: doughnutText
        });
        (install as any).done = true;
    }
}
