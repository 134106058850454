
/** Filter the supported format
 * return bool value if the format is one of the supported formats -> supportedOutputFormatsArray
 */
const filterSupportedOutputFormat = (format) => {
    var supportedOutputFormatsArray = ['html', 'excel_xlsx', 'csv', 'csv_utf8', 'excel'];
    return supportedOutputFormatsArray.includes(format);
};

/** Return the array of supported output formats*/
export const supportedOutputFormat = (supportedOutputFormats) => {
    var newSupportedOutputFormats = [];
    if (supportedOutputFormats && (supportedOutputFormats[0] === 'all' || supportedOutputFormats === 'all')) {
        newSupportedOutputFormats = ['html', 'csv', 'csv_utf8', 'excel'];
    } else if (Array.isArray(supportedOutputFormats)) {
        newSupportedOutputFormats = supportedOutputFormats.filter(filterSupportedOutputFormat);
    } else {
        newSupportedOutputFormats = ['html', 'csv', 'csv_utf8', 'excel'];
    }
    return newSupportedOutputFormats;
};
