import React from 'react';

interface ILegendItem {
    value: string;
    color: string;
    description: string;
}

interface IProps {
    legends?: Array<ILegendItem>;
}

/**
 * Chart Legends component
 */
const LegendsComponent: React.SFC<IProps> = (props: IProps) => {
    const listStyle: React.CSSProperties = {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        textAlign: 'left',
    };
    const itemStyle: React.CSSProperties = {
        whiteSpace: 'nowrap',
    };
    const labelStyle: React.CSSProperties = {
        color: '#666666',
        display: 'inline-block',
        maxWidth: 120,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
    const colorBlockStyle: React.CSSProperties = {
        display: 'inline-block',
        height: 14,
        marginRight: 5,
        width: 20,
    };

    let legends = props.legends || [];
    const listItems = legends.map((legend: ILegendItem, index: number) => (
        <li key={index} style={itemStyle}>
            <div style={{backgroundColor: legend.color, ...colorBlockStyle}} title={legend.description}></div>
            <span style={labelStyle} title={legend.description}>{legend.value}</span>
        </li>
    ));
    return (
        <ul style={listStyle}>{listItems}</ul>
    );
};

export default LegendsComponent;
