import React, { Component } from 'react';
import { Icon } from '@rmwc/icon';
import Markdown from 'react-markdown';
import { AppControl } from '../core/appcontrol';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import '../styles/agent-notification-popoup.scss';
import axios from 'axios';

interface AgentNotificationPopUpProps {
    translate: (key: string) => string;
    onClose?: () => any;
    popupData?: any;
}

interface AgentNotificationPopUpState {
    contentMarkdown: string;
    open: boolean;
    checked: boolean;
}

/**
 * Popup for claiming that the application version is too old
 */
class AgentNotificationPopUp extends Component<AgentNotificationPopUpProps, AgentNotificationPopUpState> {
    private popupIsNull = true;
    constructor(props: AgentNotificationPopUpProps) {
        super(props);
        this.state = {
            contentMarkdown: '',
            open: true,
            checked: false,
        };
    }

    componentDidMount() {
        if (this.props.popupData) {
            const key = this.props.popupData;
            const version = AppControl.getValue('version');
            let stringVersion = '';
            let popupContent = '';
            if (typeof version !== 'undefined') {
                stringVersion = '(' + version.charAt(0) + '.' + version.substring(1, 3) + ')';
                popupContent = AppControl.replaceParamsInCurlyBrackets('' + key.image + '\n' + key.title + '\n' + key.body, {
                    version: stringVersion,
                });
            } else {
                popupContent = key.image + '\n' + key.title + '\n' + key.body;
            }
            this.setState({
                contentMarkdown: popupContent,
            });
        }
    }

    sendMatomoEvent = () => {
        AppControl.pushPiwikTrackEvent(
            'Popup',
            'Pop Up',
            'Clicked from Client ID - ' + (window as any).UserInfo.client + ' Pop Up ID - ' + this.props.popupData.popup_id
        );
        this.closeDialog();
    };

    closeDialog = () => {
        this.setState({ open: false }, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        });
        if (this.state.checked) {
            axios.post('?page=ajax_controller&module=PopUp&cmd=set_popup_agent_settings', { popupId: this.props.popupData.popup_id });
        }
    };

    handleInputChange = event => {
        this.setState({
            checked: event.target.checked,
        });
    };

    render() {
        const { translate } = this.props;
        return (
            <>
                <Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    open={this.state.open}
                    onClose={this.closeDialog}
                    className="agent-notification-dialog"
                >
                    <Icon icon="close" onClick={this.closeDialog} tabIndex={0} />
                    <DialogContent>
                        {/* Render the markdown component */}
                        <Markdown escapeHtml={true} source={this.state.contentMarkdown} />
                        <DialogActions>
                            <Button className="ok-button" autoFocus onClick={this.sendMatomoEvent}>
                                OK
                            </Button>
                            {this.props.popupData.display == 'once' ? (
                                <p>
                                    <input name="checkBox" type="checkbox" className={'custom-class'} onChange={this.handleInputChange} />
                                    <span>{translate('generic.outbound.popup.button')}</span>
                                </p>
                            ) : (
                                ''
                            )}
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default AgentNotificationPopUp;
