export class AppControl {

    /**
     * Get value from application
     * @param {string} key requested value key
     * @return {string} requested value or undefined
     */
     static getValue(key) {
        const ld = (window as any).LD;
        if (typeof ld === 'undefined' || key === undefined) {
            return undefined;
        }
        return ld.executeGetCommand(key);
    }

    /**
     * Replace the keyNames found in a string to given values.
     *
     * @param {string} theString the string that contains the key that needs to be replaced
     * @param {object} params Params as an object array. For example: {key: value, key: value}.
     * Key becomes "{key}" that will be replaced with "value" in the string found with keyName.
     * @return {string} the wanted string and markers replaced
     */
    static replaceParamsInCurlyBrackets(theString, params) {
        Object.keys(params).map(function(keyName, keyIndex) {
            theString = theString.replace('{' + keyName + '}', params[keyName]);
        });
        return theString;
    }

    /**
     * function that send the matomo event for analytics.
     */
    static pushPiwikTrackEvent(category, action, value) {
        if (typeof (window as any)._paq !== 'undefined') {
            (window as any)._paq.push(['trackEvent', category, action, value]);
        }
    }

}
