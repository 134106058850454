import React, {Component} from 'react';
import ChartComponent from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import LegendsComponent from './LegendsComponent';
import 'whatwg-fetch';
import {lang} from '../core/localization';

interface IChartOverlay {
    major?: string;
    minor?: string;
    suffix?: string;
}

interface IChartData extends Chart.ChartData {
    descriptions?: Array<string>;
    overlay?: IChartOverlay;
}

interface IChartCenterPlugin {
    color: string;
    textMajor: string;
    textMinor: string;
    textMinorSuffix: string;
}

interface IChartPlugins {
    center?: IChartCenterPlugin;
}

interface IChartOptions extends Chart.ChartOptions {
    plugins?: IChartPlugins;
}

interface IState {
    data: IChartData | null;
    error?: string;
}

interface IProps {
    type: Chart.ChartType;
    url: string;
    title?: string;
    dataFormatter: Function;
    options?: IChartOptions;
    legend?: 'top' | 'bottom';
    width: number;
    height: number;
}

/**
 * Component that loads chart data from the backend and then renders it with Chart.js
 */
export default class AjaxChartComponent extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: null,
        };
    }

    public dataSanitiser(data: any) {
        if (this.props.type === 'doughnut') {
            // If doughnut chart does not have any data > 0, then draw full grey doughnut
            const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
            if (sum === 0) {
                data.datasets[0].data.push(0, 1);
                this.props.options.tooltips = {
                    enabled: false,
                };
            }
            return data;
        }
        return data;
    }

    public componentDidMount() {
        const fetchOptions: RequestInit = {
            method: 'GET',
            credentials: 'same-origin',
        };
        fetch(this.props.url, fetchOptions)
            .then((response: any) => response.json())
            .then((data: object) => this.setState({data: this.dataSanitiser(this.props.dataFormatter(data))}))
            .catch(() => this.setState({error: lang('admin.contact_list.stats.load.error')}));
    }

    public renderLegends() {
        let legends = [];
        let data = this.state.data;
        if (this.props.type !== 'doughnut') {
            legends = data.datasets.map((set: any, index: number) => {
                const legend = {
                    color: set.backgroundColor,
                    index,
                    value: set.label,
                    description: set.label,
                };
                return legend;
            });
        } else {
            if (data.labels && data.datasets.length > 0) {
                legends = data.labels.map((val: string, index: number) => {
                    const legend = {
                        color: data.datasets[0].backgroundColor[index] || '#fff',
                        index,
                        value: val,
                        description: data.descriptions[index] || val,
                    };
                    return legend;
                });
            }
        }

        const colLegendTopStyle = {
            alignSelf: 'flex-start',
            flex: 1,
            marginLeft: 10,
            marginTop: 6,
        };
        const colLegendBottomStyle = {
            alignSelf: 'flex-end',
            flex: 1,
            marginLeft: 10,
        };

        return (
            <div style={this.props.legend === 'top' ? colLegendTopStyle : colLegendBottomStyle}>
                <LegendsComponent legends={legends} />
            </div>
        );
    }

    public render() {
        let {height, width} = this.props;
        const totalHeight = height + 19; // title + margin
        const totalWidth = width + (this.props.legend ? 155 : 0);
        if (this.state.error) {
            const errorStyle = {
                color: '#d0021b',
                display: 'block',
                fontSize: '4em',
                height: totalHeight * 0.7,
                paddingTop: totalHeight * 0.3,
                width: totalWidth,
            };
            return (
                <div style={errorStyle}>
                    <span className="glyphicons glyphicons-warning-sign" title={this.state.error}></span>
                </div>
            );
        }
        if (this.state.data === null) {
            const loaderStyle = {
                display: 'block',
                height: totalHeight * 0.7,
                paddingTop: totalHeight * 0.3,
                width: totalWidth,
            };
            return (
                <div style={loaderStyle}>
                    <CircularProgress style={{color: '#ccc'}} />
                </div>
            );
        }

        const flexStyle = {
            display: 'flex',
        };
        const colGraphStyle: React.CSSProperties = {
            flex: 1,
            position: 'relative',
            textAlign: 'left',
        };
        const titleStyle: React.CSSProperties = {
            color: '#000',
            display: 'block',
            fontFamily: 'Roboto-Bold',
            marginBottom: 5,
            textAlign: 'left',
        };
        let legend: any = '';
        if (this.props.legend) {
            if (this.state.data.datasets.length > 0) {
                legend = this.renderLegends();
            } else {
                // expand chart if no legend data
                width += 155;
            }
        }

        if (this.state.data.overlay) {
            this.props.options.plugins = {
                center: {
                    color: '#bab9ba',
                    textMajor: this.state.data.overlay.major,
                    textMinor: this.state.data.overlay.minor,
                    textMinorSuffix: this.state.data.overlay.suffix,
                }
            };
        }

        return (
            <div>
                <span style={titleStyle}>{this.props.title}</span>
                <div style={flexStyle}>
                    <div style={colGraphStyle}>
                        <ChartComponent
                            ref='chart'
                            type={this.props.type}
                            data={this.state.data}
                            width={width}
                            height={height}
                            options={this.props.options}
                            legend={{display: false}}
                        />
                    </div>
                    {legend}
                </div>
            </div>
        );
    }
}
