import * as picoModal from 'picomodal';
import './survey.scss';
import axios from 'axios';
import {AppControl} from '../core/appcontrol';

(function () {
    var init = function () {
        if (!window.UserInfo) {
            return;
        }
        if (location.href.match('_survey=1')) {
            show();
            return;
        }
        if (!SettingsVault.sessionStorage().npsSurvey().isTriggered()
            && window.matchMedia('(min-width: 1024px)').matches && Math.random() <= 0.01) {
            show();
        }
        SettingsVault.sessionStorage().npsSurvey().setTriggered();
    };

    var show = function () {
        AppControl.pushPiwikTrackEvent('NPS Survey', 'Shown NPS Surveys',
            'Shown to Client with ID - ' + window.UserInfo.client);
        picoModal({
            content: '\
                <h1>User Survey</h1>\
                <p>Based on your experience, how likely are you to recommend LeadDesk to a friend or colleague?</p>\
                <div class="s"><span>1</span><span>2</span><span>3</span><span>4</span><span>5</span><span>6</span><span>7</span><span>8</span><span>9</span><span>10</span></div>\
                <div class="l">\
                  <p class="pico-label negative">Not at all likely to recommend</p>\
                  <p class="pico-label positive">Extremely likely to recommend</p>\
                </div>\
                <div class="t">\
                  <h2>Why would you recommend / not recommend?</h2>\
                  <textarea rows="4"></textarea>\
                </div>\
                <div class="t__review">\
                  <h1>Review LeadDesk</h1>\
                  <p>If you enjoy using LeadDesk, would you mind taking a moment to review us? It won’t take more than a few minutes. Thanks for your support!</p>\
                  <p style="text-align: center"><a id="pico-close-t" href=" https://reviews.capterra.com/new/130134?utm_source=vp&utm_medium=none&utm_term=&utm_content=&utm_campaign=vendor_request" target="_blank" rel="noopener">Rate LeadDesk on Capterra</a></p>\
                </div>\
                <div class="pico-footer">\
                  <button id="pico-submit">Send</button>\
                  <a id="pico-close" class="pico-label" href="#">Skip the questionnaire</a>\
                </div>\
                ',
            width: 427,
            closeButton: true,
            overlayStyles: {
                backgroundColor: '#000',
                opacity: 0.6
            },
            closeStyles: {
                position: 'absolute',
                top: '5px',
                right: '10px',
                backgroundColor: '#fff',
                cursor: 'pointer',
                fontSize: '18px',
                color: '#888'
            }
        }).afterCreate(modal => {

            document.getElementById('pico-close').addEventListener('click', function () {
                modal.close();
            });

            document.getElementById('pico-close-t').addEventListener('click', function () {
                modal.close();
            });

            [].forEach.call(document.querySelectorAll('.pico-content .s span'), function (el) {
                el.addEventListener('click', function () {
                    [].forEach.call(document.querySelectorAll('.pico-content .s span'), function (el) {
                        el.classList.remove('selected');
                    });
                    el.classList.add('selected');
                    document.getElementById('pico-submit').style.visibility = 'visible';
                    document.querySelector<HTMLDivElement>('.pico-content .t').style.display = 'block';
                });
            });

            document.getElementById('pico-submit').addEventListener('click', function () {
                var rating = document.querySelector<HTMLSpanElement>('.pico-content span.selected').innerText;
                var answer = document.querySelector<HTMLTextAreaElement>('.pico-content textarea').value || '';
                axios.post('?page=ajax_controller&module=NpsSurvey&cmd=insert', {rating, answer});
                AppControl.pushPiwikTrackEvent('NPS Survey', 'Replied NPS Surveys',
                    'Survey rating from Client: ' + JSON.stringify({
                        clientID: window.UserInfo.client,
                        rating: rating
                    }));
                if (parseInt(rating, 10) >= 9) {
                    [].forEach.call(document.querySelectorAll('.pico-content > *:not(.pico-close)'), function (el) {
                        el.style.display = 'none';
                    });
                    document.querySelector<HTMLDivElement>('.pico-content .t__review').style.display = 'block';
                } else {
                    modal.close();
                }
            });
        }).afterClose((modal, event) => {

        }).show();
    };

    if (document.readyState == 'complete') {
        init();
    } else {
        window.addEventListener('load', init);
    }
})();
